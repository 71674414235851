import React from "react";
import "./Footer.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFaceSmileBeam } from "@fortawesome/free-solid-svg-icons";
import { Container } from "react-bootstrap";

function Footer({ setPage }) {
  return (
    <div>
      <Container>
      
        <footer className="footer">
          <div className="containers">
            <div className="row">
              <div className="footer-col">
                <h4>About</h4>
                <ul>
                  <li>
                    <a onClick={() => { setPage("aboutus") }}>About Us</a>
                  </li>
                  <li>
                    <a onClick={() => { setPage("career") }}>Career</a>
                  </li>
                  <li>
                    <a onClick={() => { setPage("team") }}>Team</a>
                  </li>
                </ul>
              </div>
              <div className="footer-col">
                <h4>Services</h4>
                <ul>
                  <li>
                    <a onClick={() => { setPage("itservices") }}>IT Services</a>
                  </li>
                  <li>
                    <a onClick={() => { setPage("business") }}>Business</a>
                  </li>
                  <li>
                    <a onClick={() => { setPage("hrsolutions") }}>HR Solutions</a>
                  </li>
                  <li>
                    <a onClick={() => { setPage("erpsolutions") }}>ERP Solutions</a>
                  </li>
                </ul>
              </div>
              <div className="footer-col">
                <h4>Quick links</h4>
                <ul>
                  <li>
                    <a onClick={() => { setPage("aboutus") }}>About</a>
                  </li>
                  <li>
                    <a onClick={() => { setPage("portfolio") }}>Portfolio</a>
                  </li>
                  <li>
                    <a onClick={() => { setPage("clients") }}>Clients</a>
                  </li>
                  
                </ul>
              </div>
              <div className="footer-col">
                <h4>follow us</h4>
                <div className="social-links">
                  <a href="https://www.facebook.com/digitaltriumph.in" target="blank">
                    <svg xmlns="http://www.w3.org/2000/svg" height="1.8em" viewBox="0 0 512 512" style={{ fill: "#ffffff" }}><path d="M504 256C504 119 393 8 256 8S8 119 8 256c0 123.78 90.69 226.38 209.25 245V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.28c-30.8 0-40.41 19.12-40.41 38.73V256h68.78l-11 71.69h-57.78V501C413.31 482.38 504 379.78 504 256z" /></svg>
                  </a>

                  <a href="https://www.instagram.com/digital_triumph/" target="blank">
                    <svg xmlns="http://www.w3.org/2000/svg" height="1.8em" viewBox="0 0 448 512" style={{ fill: "#ffffff" }}><path d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z" /></svg>
                  </a>
                  <a href="https://www.linkedin.com/company/digital-triumph-private-limited/" target="blank">
                    <svg xmlns="http://www.w3.org/2000/svg" height="1.8em" viewBox="0 0 448 512" style={{ fill: "#ffffff" }}><path d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z" /></svg>
                  </a>
                </div>
                <div className="foot">
                  <section>
                    <h3>ANY QUERIES?</h3>
                    <a href="emailto:hrchennai@digitaltriumph.in">
                      <span>
                        <i className="fa fa-envelope"></i>
                      </span>
                      <span>contact@digitaltriumph.in</span>
                    </a>
                    <a href="tel:xxxxxxxxx">
                      <span>
                        <i className="fa fa-phone"></i>
                      </span>
                      <span>+9144 3557 5582</span>
                    </a>
                    <a href="tel:xxxxxxxxx">
                      <span>
                        <i className="fa fa-phone"></i>
                      </span>
                      <span>+91 9150564053</span>
                    </a>
                  </section>
                </div>
              </div>
            </div>
          </div>
          <div>
            <p style={{textAlign: "center", color: "white", fontSize: "16px", fontWeight: "300", opacity: ".8"}}>© Digital Triumph | All Right Reserved | <a style={{color: "white", fontSize: "16px", fontWeight: "300"}} onClick={() => { setPage("privacypolicy") }}>Privacy Policy</a></p>
          </div>
        </footer>

        <div className='wap'>
    <a target="_blank" href="https://web.whatsapp.com/send?phone=+919150564053&text=Hi%20Digital Triumph!">
        <img src={require('../../Assests/logo/whatsapp_PNG1.png')} width="63px" alt="whatsapp icon" /></a>

</div>
      </Container>
    </div>
  );
}

export default Footer;
