import React, { useEffect, useState } from "react";
import "../Itservice/Itservice.css"
import { Row, Col, Container } from "react-bootstrap";
import RINGS from "vanta/src/vanta.waves";
import Aos, { init } from "aos";
import "aos/dist/aos.css";
import { Helmet } from "react-helmet";

function Itservices({ setPage }) {
    useEffect(() => {
        RINGS({
            el: "#rings",
            mouseControls: true,
            touchControls: true,
            gyroControls: false,
            minHeight: 200.0,
            minWidth: 200.0,
            scale: 1.0,
            scaleMobile: 1.0,
            color: 0x24262b,
        });
    }, []);


    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    }, []);


    const currentPathname = window.location.pathname;
    const newPathname = "/itservices";
    window.history.pushState({}, "", newPathname);

    useState(() => {
        Aos.init({ duration: 2000 });
    }, []);

    return (
        <div className="itservices">
            <Helmet>
                <title>IT-Services - Unlock the potential of technology with our comprehensive IT services.</title>
                <meta name="description" content="Unlock the potential of technology with our comprehensive IT services." />
            </Helmet>
            {/* <Navbars /> */}
            <div>
                <div className="bannersection">
                    <div style={{ height: "100vh" }} className="bg" id="rings">
                        <Container>
                            <Row>
                                <Col lg={12} className="column">
                                    <div className="bannertitle">
                                        <p>IT Services</p>
                                        <h1>
                                            Unlock the potential of technology with our comprehensive IT services.
                                        </h1>
                                        <div classname="ctus" style={{ display: "flex", justifyContent: "end", marginTop: "55px", alignItems: "end" }}>
                                            <button onClick={() => { setPage("contactus") }} style={{ border: "none", padding: "10px 20px", borderRadius: "40px", top: "69%", position: "fixed" }}>Contact us</button>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </div>
            </div>
            <Container>
                <Row>
                    <Col lg={12}>
                        <div className="itservicecol3">
                            <p>What Do We Serve?</p>
                            <h1>We specialize in transforming your basic IT concepts into captivating and innovative digital solutions, crafting an extraordinary vision for your IT services.</h1>
                        </div>
                    </Col>
                </Row>
                <Row className="itservicerow1">
                    <Col lg={6}>
                        <div className="itservicecol4">
                            <div className="itservicecol5">
                                <img width="10%" src={require("../../Assests/icons/webdevelopmenticon.gif")} />
                                <h1>Web Application Development</h1>
                            </div>
                            <div className="itservicecol5">
                                <img width="10%" src={require("../../Assests/icons/appdevelopment.gif")} />
                                <h1>Mobile Application Development</h1>
                            </div>
                            <div className="itservicecol5">
                                <img width="10%" src={require("../../Assests/icons/customsoftware.gif")} />
                                <h1>Custom Software Development</h1>
                            </div>

                        </div>
                    </Col>

                    <Col lg={6}>
                        <div className="itservicecol4">
                            <div className="itservicecol5">
                                <img width="10%" src={require("../../Assests/icons/cloudservice.gif")} />
                                <h1>Cloud Services & Data Management</h1>
                            </div>

                            <div className="itservicecol5">
                                <img width="10%" src={require("../../Assests/icons/crmicon.gif")} />
                                <h1>CRM / ERP Development</h1>
                            </div>
                        </div>

                    </Col>
                </Row>
            </Container>

            <Container fluid>
                <Row className="itservicerow2">
                    <div className="itservicediv">
                        <p>How We Do It?</p>
                        <p style={{ color: "white", marginBottom: "50px", fontSize: "18px", fontWeight: "400" }}>Navigating Complexity, Delivering Comprehensive IT Services.</p>
                    </div>
                    <Col lg={6} className="itservicecol6" data-aos="fade-right">
                        <div>
                            <img width="10%" src={require("../../Assests/icons/webdevelopmenticon.gif")} />
                            <h1>Web Application Development</h1>
                            <p>Unleash seamless digital experiences with our web application development prowess. Our expert IT team converts innovative concepts into functional dynamic applications, tailored to your specific requirements. Leveraging advanced tech and user-focused design, we optimize engagement, efficiency, and business expansion, providing end-to-end solutions for your web presence.</p>
                        </div>
                    </Col>

                    <Col lg={6} style={{ padding: "0px" }} data-aos="fade-left">
                        <img width="100%" src={require("../../Assests/itservicesimages/webdevelopimages.jpg")} />
                    </Col>

                </Row>


                <Row className="itservicerow2" id="itreverserow">
                    <Col lg={6} style={{ padding: "0px" }} data-aos="fade-right">
                        <img width="100%" src={require("../../Assests/itservicesimages/mobileappdevelopimages.jpg")} />
                    </Col>

                    <Col lg={6} className="itservicecol6" data-aos="fade-left">
                        <div>
                            <img width="10%" src={require("../../Assests/icons/appdevelopment.gif")} />
                            <h1>Mobile Application Development</h1>
                            <p>Embark on a transformative mobile app development journey with our expertise. Our IT specialists shape imaginative concepts into tailored mobile apps, integrating advanced tech and user-centric design to enhance engagement and fuel growth. From inception to deployment and maintenance, we're your partners in leveraging mobile platforms for remarkable results.</p>
                        </div>
                    </Col>

                </Row>

                <Row className="itservicerow2" >

                    <Col lg={6} className="itservicecol6" >
                        <div data-aos="fade-right" >
                            <img width="10%" src={require("../../Assests/icons/customsoftware.gif")} />
                            <h1>Custom Software Development</h1>
                            <p>
                                Experience the potential of bespoke solutions through our custom software development services. Our skilled IT experts excel in designing distinctive software tailored to your exact business requirements. By prioritizing innovation and accuracy, we develop applications that streamline operations, boost efficiency, and steer your company toward triumph.</p>
                        </div>
                    </Col>

                    <Col lg={6} data-aos="fade-left" style={{ padding: "0px" }}>
                        <img width="100%" src={require("../../Assests/itservicesimages/softwaredevelopment.jpg")} />
                    </Col>

                </Row>

                <Row className="itservicerow2" id="itreverserow" >
                    <Col lg={6} style={{ padding: "0px", display: "flex", alignItems: "center" }}>
                        <img width="100%" src={require("../../Assests/itservicesimages/database.jpg")} />
                    </Col>

                    <Col lg={6} className="itservicecol6">
                        <div>
                            <img width="10%" src={require("../../Assests/icons/cloudservice.gif")} />
                            <h1>Cloud Services & Data Management</h1>
                            <p>Embark on a seamless digital path through our inclusive cloud services and data management solutions. Our IT specialists excel in designing secure and scalable cloud structures that enhance your operations. From data migration to analysis, we maximize data potential while ensuring accessibility and security. Elevate your business with our avant-garde cloud services and data management prowess.</p>
                        </div>
                    </Col>

                </Row>


                <Row className="itservicerow2">

                    <Col lg={6} className="itservicecol6">
                        <div data-aos="fade-right">
                            <img width="10%" src={require("../../Assests/icons/crmicon.gif")} />
                            <h1>CRM / ERP Development</h1>
                            <p>Explore customized business solutions through our specialized CRM and ERP development. Our IT experts excel in creating seamless CRM and ERP systems tailored to your operations. From refining customer interactions to optimizing internal processes, our solutions drive growth and efficiency. Enhance your business with our exceptional CRM and ERP development services.</p>
                        </div>
                    </Col>

                    <Col lg={6}  data-aos="fade-left" style={{ padding: "0px" }} >
                        <img width="100%" src={require("../../Assests/itservicesimages/crmimages.jpg")} />
                    </Col>
                </Row>
            </Container>

        </div>
    )
}

export default Itservices;