import React from "react";
import "../../Components/ContactUs/ContactUs.css"
import Aos, { init } from "aos";
import "aos/dist/aos.css";
import { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Time from "../Time/Time";
import Dubai from "../Dubai/Dubai";
import ContactForm from "./Contactform/Contactform";
import Belgium from "../Dubai/Belgium";
import CLOUDS from "vanta/src/vanta.net";
import { Helmet } from "react-helmet";



function ContactUs({ setPage }) {

    var item_value = sessionStorage.getItem("item_key");

    sessionStorage.setItem("item_key", item_value);

    useState(() => {
        Aos.init({ duration: 2000 });
    }, []);

    const currentPathname = window.location.pathname;
    const newPathname = "/contactus";
    window.history.pushState({}, "", newPathname);

    useEffect(() => {
        CLOUDS({
            el: "#vanta",
            mouseControls: true,
            touchControls: true,
            gyroControls: false,
            minHeight: 200.00,
            minWidth: 200.00,
            scale: 1.00,
            scaleMobile: 1.00,
            color: 0x969696,
            backgroundColor: 0x24262b,
            blurFactor: 0.90,
        })
    }, [])

    return (
        <div className="contactus">
            <Helmet>
                <title>Contact us - Give us a call or drop by anytime.</title>
                <meta name="description" content="Give us a call or drop by anytime." />

            </Helmet>
            <div className='bannersection'>

                <div style={{ height: "100vh" }} className='bg' id='vanta'>
                    <Container>
                        <Row>
                            <Col lg={12} className="column">

                                <div className='bannertitle'>
                                    <p className="talk">Let's Talk.</p>
                                    <h1>Give us a call or drop by anytime, We endeavor to answer all inquiries to contact us within 24 hours on business days.</h1>
                                </div>
                            </Col>

                        </Row>

                    </Container>
                </div>

            </div>
            <Container>

                <Row className="pad">

                    <Col lg={6} className="glass">
                        <ContactForm setPage={setPage} />
                    </Col>
                    <Col lg={6} className="dis">
                        <div>
                            <img width="80%" src={require("../../Assests/contactusimage1.png")} />
                        </div>
                        <div>
                            <h1>For Quick Contact:</h1><br />
                            <h2 className="number">Our Phone: <a style={{ color: "white", marginLeft: "10px", textDecoration: "none" }} href="tel:+91 44 3557 5582"> +91 44 3557 5582</a></h2>
                            <h2 className="number">Our Mailbox:<a style={{ color: "white", marginLeft: "10px", textDecoration: "none" }} href="mailto:contact@digitaltriumph.in"> contact@digitaltriumph.in</a></h2>
                        </div>
                    </Col>

                </Row>
            </Container>


            <div className="contactuscontainer">
                <Container>
                    <Row>
                        <Col lg={4} md={6} sm={12}>
                            <div className="address">
                                <h1 className="ind">India</h1>
                                <h1><Time /></h1>
                                <div className="chennai">
                                    <h1>Chennai:</h1>
                                    <h2>ADDRESS: AC 149, Plot No 4920, AC Block, 3rd Floor, Achu Towers, 2nd Avenue, Anna Nagar, Chennai-600040</h2>
                                </div>
                                <div className="hyd">
                                    <h1>Hyderabad:</h1><h2>ADDRESS: D.No. 8-3-944/15, Flat No. 202, Sree Rama Enclave, Yellareddyguda, Ameerpet,
                                        Hyderabad - 500 073.</h2><br />
                                </div>

                                <div>
                                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3886.22968402171!2d80.2080045738507!3d13.084624812392656!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a52641fa412dfe7%3A0x598086211397c0d0!2sDigital%20Triumph%20Private%20Limited!5e0!3m2!1sen!2sin!4v1692005882409!5m2!1sen!2sin" width="300" height="200" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                </div>
                            </div>
                        </Col>
                        <Col lg={4} md={6} sm={12}>
                            <div className="address2">
                                <h1 className="dub">UAE</h1>
                                <h1><Dubai /></h1>
                            </div>
                        </Col>
                        <Col lg={4} md={6} sm={12}>
                            <div className="address2">
                                <h1 className="dub">Belgium</h1>
                                <h1><Belgium /></h1>
                                <h2 className="bel">ADDRESS: Onze-Lieve-V. ten Steenstraat 10, 3300 Tienen.</h2>

                            </div>
                        </Col>
                    </Row>

                </Container>
            </div>

        </div>
    )
};

export default ContactUs;
