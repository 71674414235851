import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Aos, { init } from "aos";
import "aos/dist/aos.css";
import { useState } from "react";
import "../HRSolutions/HRSolutions.css";
import RINGS from "vanta/src/vanta.waves";
import { Helmet } from "react-helmet";



function HRSolutions() {
    useState(() => {
        Aos.init({ duration: 2000 });
    }, []);


    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    }, []);

    const currentPathname = window.location.pathname;
    const newPathname = "/hrsolutions";
    window.history.pushState({}, "", newPathname);

    useEffect(() => {
        RINGS({
            el: "#rings",
            mouseControls: true,
            touchControls: true,
            gyroControls: false,
            minHeight: 200.0,
            minWidth: 200.0,
            scale: 1.0,
            scaleMobile: 1.0,
            color: 0x24262b,
        });
    }, []);


    return (
        <div className="hrsolution">
            <Helmet>
                <title>HR-Solutions - Unlocking Human Potential Through Tailored HR Solutions.</title>
                <meta name="description" content="Unlocking Human Potential Through Tailored HR Solutions." />
            </Helmet>

            <div>
                <div className="bannersection">
                    <div style={{ height: "100vh" }} className="bg" id="rings">
                        <Container>
                            <Row>
                                <Col lg={12} className='column'>
                                    <div className="bannertitle">
                                        <p>HR Solutions</p>
                                        <h1>
                                            Unlocking Human Potential Through Tailored HR Solutions.
                                        </h1>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </div>
            </div>

            <Container>
                <Row className="row1">
                    <Col lg={12} className="col4">
                        <div data-aos="fade-up" className="uno1">
                            <h2>What do we serve?</h2>
                        </div>
                        <div data-aos="fade-up" className="uno">
                            <h1 className="role">HR solutions play a pivotal role in shaping modern workplaces, providing comprehensive and strategic support to organizations in managing their human resources effectively. </h1>
                        </div>

                    </Col>
                    <Row className="rowww">
                        <Col lg={6}>
                            <div className="solution">
                                <img width="10%" className="imggg" src={require("../../Assests/clientimg/contract.gif")} />
                                <h1>Contract Staffing</h1>
                            </div>
                            <div className="solution">
                                <img width="10%" className="imggg" src={require("../../Assests/clientimg/recruitment.gif")} />
                                <h1>Recruitment</h1>
                            </div>
                            <div className="solution">
                                <img width="10%" className="imggg" src={require("../../Assests/clientimg/search.gif")} />
                                <h1>Executive Search</h1>
                            </div>
                        </Col>
                        <Col lg={6}>
                            <div className="solution">
                                <img width="10%" className="imggg" src={require("../../Assests/clientimg/demand.gif")} />
                                <h1>Recruitment Process Outsourcing</h1>
                            </div>
                            <div className="solution">
                                <img width="10%" className="imggg" src={require("../../Assests/clientimg/compliance.gif")} />
                                <h1>Compliance Management</h1>
                            </div>
                        </Col>
                    </Row>
                </Row>
            </Container>
            <Container fluid>
                <Row className="row2">
                    <div className="itservicediv">
                        <p>How We Do It?</p>
                        <p style={{ color: "white", marginBottom: "50px", fontSize: "18px", fontWeight: "400" }}>Elevating teams through our tailored HR excellence.</p>
                    </div>
                    <Col lg={6} className="col5-padding">

                        <div className="section" data-aos="fade-left" >
                            <img  width="10%" className="imggg" src={require("../../Assests/clientimg/contract.gif")} />
                            <h1 data-aos="fade-right">Contract Staffing</h1>
                            <p data-aos="fade-right">Amid the ever-changing IT landscape, contract staffing, also termed as temporary or contingent staffing, stands as a sought-after workforce solution. It involves securing skilled IT professionals for precise periods, tailored to project needs or short-term talent requirements. Our IT-focused contract staffing approach embodies the agility demanded by modern tech enterprises, ensuring your team stays responsive in a dynamic industry.</p>
                        </div>
                    </Col>

                    <Col lg={6} data-aos="fade-left" style={{ padding: "0px", display: "flex", alignItems: "center" }} className="col6">
                        <img width="100%" src={require("../../Assests/clientimg/hrsolutions.jpg")} />
                    </Col>
                </Row>
                <Row className="row2" id="itreverserow">


                    <Col lg={6} data-aos="fade-right" style={{ padding: "0px", display: "flex", alignItems: "center" }} className="col5">

                        <img width="100%" src={require("../../Assests/clientimg/recruitment.jpg")} />
                    </Col>

                    <Col lg={6} className="col6-padding">

                        <div data-aos="fade-left" className="section">
                            <img width="10%" className="imggg" src={require("../../Assests/clientimg/recruitment.gif")} />

                            <h1>Recruitment</h1>
                            <p>Explore our dynamic IT Recruitment Process Outsourcing (RPO), partnering with expert externals to enhance your hiring. They seamlessly integrate into your HR, securing top-tier tech talent. As a team extension, we enhance selection through technology and insights. Elevate your IT hiring with our bespoke RPO services, gaining a competitive edge in retaining premier tech professionals.</p>
                        </div>
                    </Col>

                </Row>
                <Row className="row2">
                    <Col lg={6} className="col5-padding">

                        <div data-aos="fade-right" className="section">
                            <img width="10%" className="imggg" src={require("../../Assests/clientimg/search.gif")} />
                            <h1>Executive Search</h1>
                            <p>In our IT realm, executive search, also known as "headhunting," is a specialized strategy. It precisely identifies and attracts top-tier candidates for senior IT roles, leveraging their expertise and leadership. Unlike standard recruitment, it delves deeper, tapping exclusive networks to secure IT trailblazers shaping our tech-driven future.</p>
                        </div>
                    </Col>


                    <Col lg={6} data-aos="fade-left" style={{ padding: "0px", display: "flex", alignItems: "center" }} className="col6">
                        <img width="100%" src={require("../../Assests/clientimg/executive search.jpg")} />
                    </Col>
                </Row>
                <Row className="row2" id="itreverserow">


                    <Col lg={6} data-aos="fade-right" style={{ padding: "0px", display: "flex", alignItems: "center" }} className="col5">
                        <img width="100%" src={require("../../Assests/clientimg/rpo.jpg")} />
                    </Col>
                    <Col lg={6} className="col6-padding">
                        <div data-aos="fade-left" className="section">
                            <img width="10%" className="imggg" src={require("../../Assests/clientimg/demand.gif")} />

                            <h1>Recruitment Process Outsourcing</h1>
                            <p>Recruitment Process Outsourcing (RPO), a dynamic solution we offer, involves collaborating with dedicated external experts to streamline and enhance your IT hiring procedures. These specialists seamlessly integrate into your HR operations, utilizing their prowess in sourcing, assessing, and securing exceptional IT talent. As an extension of your team, we harness technology and data-driven insights to optimize the selection process. With our IT-specific RPO services, your organization gains the edge in attracting and retaining top-tier technology professionals.</p>
                        </div>
                    </Col>

                </Row>
                <Row className="row2">
                    <Col lg={6} className="col5-padding">

                        <div data-aos="fade-right" className="section">
                            <img width="10%" className="imggg" src={require("../../Assests/clientimg/compliance.gif")} />
                            <h1>Compliance Management</h1>
                            <p>Compliance management is a vital aspect of our IT framework, ensuring adherence to laws, regulations, industry benchmarks, and internal protocols. It involves creating, executing, and overseeing systems to maintain ethical standards in our operations. Our meticulous strategies protect our technological pursuits and underscore our commitment to top-tier IT standards.</p>
                        </div>
                    </Col>


                    <Col lg={6} data-aos="fade-left" style={{ padding: "0px", display: "flex", alignItems: "center" }} className="col6">
                        <img width="100%" src={require("../../Assests/clientimg/compliance.jpg")} />
                    </Col>
                </Row>

            </Container>

        </div>
    )
};
export default HRSolutions;
