import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Aos, { init } from "aos";
import "aos/dist/aos.css";
import { useState } from "react";
import "../../Components/ERP Solutions/ERPsolution.css";
import RINGS from "vanta/src/vanta.waves";
import { Helmet } from "react-helmet";


function ERPSolutions() {
    useState(() => {
        Aos.init({ duration: 2000 });
    }, []);


    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    }, []);

    const currentPathname = window.location.pathname;
    const newPathname = "/erpsolutions";
    window.history.pushState({}, "", newPathname);

    useEffect(() => {
        RINGS({
            el: "#rings",
            mouseControls: true,
            touchControls: true,
            gyroControls: false,
            minHeight: 200.0,
            minWidth: 200.0,
            scale: 1.0,
            scaleMobile: 1.0,
            color: 0x24262b,
        });
    }, []);


    return (
        <div className="hrsolution">
            <Helmet>
                <title>ERP-Solutions - Harness the Power of SAP ERP Consulting for Success.</title>
                <meta name="description" content="Harness the Power of SAP ERP Consulting for Success." />
            </Helmet>

            <div>
                <div className="bannersection">
                    <div style={{ height: "100vh" }} className="bg" id="rings">
                        <Container>
                            <Row>
                                <Col lg={12} className='column'>
                                    <div className="bannertitle">
                                        <p>ERP Solutions</p>
                                        <h1>
                                            Achieve Operational Excellence with SAP ERP Consulting
                                        </h1>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </div>
            </div>

            <Container>
                <Row className="row1">
                    <Col lg={12} className="col4">
                        <div data-aos="fade-up" className="uno1">
                            <h2>What do we serve?</h2>
                        </div>
                        <div data-aos="fade-up" className="uno">
                            <h1 className="role">SAP ERP solutions play a pivotal role in shaping modern workplaces, providing comprehensive and strategic support to organizations in managing their enterprise resources effectively. </h1>
                        </div>

                    </Col>
                    <Row className="rowww">
                        <Col lg={6}>
                            <div className="solution">
                                <img width="10%" className="imggg" src={require("../../Assests/clientimg/contract.gif")} />
                                <h1>SAP Implementation Services</h1>
                            </div>
                            <div className="solution">
                                <img width="10%" className="imggg" src={require("../../Assests/clientimg/recruitment.gif")} />
                                <h1>SAP S/4HANA Migration</h1>
                            </div>
                            <div className="solution">
                                <img width="10%" className="imggg" src={require("../../Assests/clientimg/search.gif")} />
                                <h1> SAP Business Intelligence (BI) and Analytics</h1>
                            </div>
                        </Col>
                        <Col lg={6}>
                            <div className="solution">
                                <img width="10%" className="imggg" src={require("../../Assests/clientimg/demand.gif")} />
                                <h1>SAP Custom Development</h1>
                            </div>
                            <div className="solution">
                                <img width="10%" className="imggg" src={require("../../Assests/ERP IMages/cloud.gif")} />
                                <h1> SAP Cloud Integration</h1>
                            </div>
                            <div className="solution">
                                <img width="10%" className="imggg" src={require("../../Assests/clientimg/compliance.gif")} />
                                <h1>SAP Support and Maintenance</h1>
                            </div>
                        </Col>
                    </Row>
                </Row>
            </Container>
            <Container fluid>
                <Row className="row2">
                    <div className="itservicediv">
                        <p>Our SAP Consulting Services</p>
                        <p style={{ color: "white", marginBottom: "50px", fontSize: "18px", fontWeight: "400" }}>Innovate Your Business Strategy with SAP ERP Consulting.</p>
                    </div>
                    <Col lg={6} className="col5-padding">

                        <div className="section" data-aos="fade-left" >
                            <img width="10%" className="imggg" src={require("../../Assests/clientimg/contract.gif")} />
                            <h1 data-aos="fade-right">SAP Implementation Services</h1>
                            <p data-aos="fade-right">Embark on your digital transformation journey with our comprehensive SAP implementation services. Our experts will guide you through every phase of the implementation process, ensuring a seamless transition to SAP solutions. From project planning and system integration to data migration and end-user training, we have you covered.</p>
                        </div>
                    </Col>

                    <Col lg={6} data-aos="fade-left" style={{ padding: "0px", display: "flex", alignItems: "center" }} className="col6">
                        <img width="100%" src={require("../../Assests/ERP IMages/SAP-Services.jpg")} />
                    </Col>
                </Row>
                <Row className="row2" id="itreverserow">


                    <Col lg={6} data-aos="fade-right" style={{ padding: "0px", display: "flex", alignItems: "center" }} className="col5">

                        <img width="100%" src={require("../../Assests/ERP IMages/S-4-HANA-migration.png")} />
                    </Col>

                    <Col lg={6} className="col6-padding">

                        <div data-aos="fade-left" className="section">
                            <img width="10%" className="imggg" src={require("../../Assests/clientimg/recruitment.gif")} />

                            <h1>SAP S/4HANA Migration</h1>
                            <p>Upgrade to the next-generation ERP platform with our SAP S/4HANA migration services. Leverage the advanced features and capabilities of S/4HANA to streamline your business processes, improve decision-making, and stay ahead of the competition. Our team will ensure a smooth migration, minimizing downtime and maximizing the benefits of the new platform.</p>
                        </div>
                    </Col>

                </Row>
                <Row className="row2">
                    <Col lg={6} className="col5-padding">

                        <div data-aos="fade-right" className="section">
                            <img width="10%" className="imggg" src={require("../../Assests/clientimg/search.gif")} />
                            <h1>SAP Business Intelligence (BI) and Analytics</h1>
                            <p>Unlock the full potential of your data with our SAP BI and analytics services. Gain valuable insights into your business performance, make informed decisions, and drive growth. We offer expertise in implementing SAP BI tools, creating custom reports and dashboards, and providing ongoing support to optimize your analytics capabilities.</p>
                        </div>
                    </Col>


                    <Col lg={6} data-aos="fade-left" style={{ padding: "0px", display: "flex", alignItems: "center" }} className="col6">
                        <img width="100%" src={require("../../Assests/ERP IMages/support.jpg")} />
                    </Col>
                </Row>
                <Row className="row2" id="itreverserow">


                    <Col lg={6} data-aos="fade-right" style={{ padding: "0px", display: "flex", alignItems: "center" }} className="col5">
                        <img width="100%" src={require("../../Assests/ERP IMages/SAP_Development.jpg")} />
                    </Col>
                    <Col lg={6} className="col6-padding">
                        <div data-aos="fade-left" className="section">
                            <img width="10%" className="imggg" src={require("../../Assests/clientimg/demand.gif")} />

                            <h1>SAP Custom Development</h1>
                            <p>Tailor SAP solutions to meet your specific business requirements with our custom development services. Our skilled developers can create bespoke applications, enhancements, and integrations that seamlessly integrate with your existing SAP landscape, enhancing functionality and addressing unique business challenges.</p>
                        </div>
                    </Col>

                </Row>
                <Row className="row2">
                    <Col lg={6} className="col5-padding">

                        <div data-aos="fade-right" className="section">
                            <img width="10%" className="imggg" src={require("../../Assests/clientimg/compliance.gif")} />
                            <h1>SAP Support and Maintenance</h1>
                            <p>Ensure the continuous reliability and performance of your SAP environment with our support and maintenance services. Our dedicated support team is available to address issues, provide updates, and implement enhancements, allowing you to focus on your core business activities while we take care of your SAP infrastructure.</p>
                        </div>
                    </Col>


                    <Col lg={6} data-aos="fade-left" style={{ padding: "0px", display: "flex", alignItems: "center" }} className="col6">
                        <img width="100%" src={require("../../Assests/ERP IMages/SapSupport.jpg")} />
                    </Col>
                </Row>
                <Row className="row2" id="itreverserow">


                    <Col lg={6} data-aos="fade-right" style={{ padding: "0px", display: "flex", alignItems: "center" }} className="col5">
                        <img width="100%" src={require("../../Assests/ERP IMages/SAP Cloud .jpg")} />
                    </Col>
                    <Col lg={6} className="col6-padding">
                        <div data-aos="fade-left" className="section">
                            <img width="10%" className="imggg" src={require("../../Assests/ERP IMages/cloud.gif")} />

                            <h1>SAP Cloud Integration</h1>
                            <p>Harness the power of the cloud with our SAP cloud integration services. Whether you are looking to migrate to SAP Cloud Platform or integrate SAP solutions with other cloud services, we have the expertise to design and implement a scalable, secure, and seamless cloud strategy for your business.</p>
                        </div>
                    </Col>

                </Row>
            </Container>

        </div>
    )
};
export default ERPSolutions;
