import React, { useEffect } from "react";
import "./Career.css";
import { Container, Row, Col } from "react-bootstrap";
import Navbars from "../Navbar/Navbar";
import BIRDS from "vanta/src/vanta.halo";
import Footer from "../Footer/Footer";
import { Helmet } from "react-helmet";

function Career({ setPage }) {

  useEffect(() => {
    BIRDS({
      el: "#birds",
      mouseControls: true,
      touchControls: true,
      gyroControls: false,
      minHeight: 200.00,
      minWidth: 200.00,
      backgroundColor: 0x24262b,
    })
  }, [])

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }, []);

  const currentPathname = window.location.pathname;
  const newPathname = "/careers";
  window.history.pushState({}, "", newPathname);

  return (
    <div className="Career">
      <Helmet>
        <title>Careers - Want to Join Our Team?</title>
        <meta name="description" content="Want to Join Our Team?" />
      </Helmet>
      <div>
        <div className='bannersection'>

          <div style={{ height: "100vh"}} className='bg' id='birds'>
            <Container>
              <Row>
                <Col lg={12} className='column'>

                  <div className='bannertitle'>
                    <p>Careers</p>
                    <h1>Want to be a part of our Team?<br />Join us in crafting IT solutions through web, mobile, and recruitment expertise.</h1>
                  </div>
                </Col>

              </Row>

            </Container>
          </div>

        </div>
      </div>

      <Container>
        <Row>
          <Col>
            <div>
              <h1 className="hire">WE ARE HIRING!!!</h1>
            </div>
            <div>
              <p className="hire2">
                Please send us your resume to <a href="mailto:hrchennai@digitaltriumph.in" style={{ color: "#6ae0e2", fontWeight: "500" }}> hrchennai@digitaltriumph.in </a> and we
                will get back if we have suitable openings for hiring. Thank you
                for your interest in advance.
              </p>
            </div>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row>
          <h1 className="bot">More About Us</h1>
        </Row>
        <Row>
          <Col>
            <section className="content yobject-marked">
              <div className="box bg-1">
                <a onClick={() => { setPage("aboutus") }}>
                  <button
                    className="button button--nina button--text-thick button--text-upper button--size-s"
                    data-text="About us"
                  >
                    <span>A</span>
                    <span>b</span>
                    <span>o</span>
                    <span>u</span>
                    <span>t</span>
                    <span style={{ color: "transparent" }}>.</span>
                    <span>u</span>
                    <span>s</span>
                  </button>
                </a>
              </div>
            </section>
          </Col>
          <Col>
            <section className="content yobject-marked">
              <div className="box bg-1">
                <a onClick={() => { setPage("team") }}>
                  <button
                    className="button button--nina button--text-thick button--text-upper button--size-s"
                    data-text="Team"
                  >
                    <span>T</span>
                    <span>e</span>
                    <span>a</span>
                    <span>m</span>
                  </button>
                </a>
              </div>
            </section>
          </Col>
        </Row>
      </Container>

    </div>
  );
}

export default Career;
