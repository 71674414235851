import React from "react";
import "../bannerservice/bannerservice.css"
import { Container, Row, Col } from "react-bootstrap";

function Bannerservice() {
    return (
        <div className="bannerservice">
            <Container>
                <Row>
                    <Col lg={12}>
                        <div>
                            <h1>Efficiency and reliability are the cornerstones of our IT services. Let us optimize your technology, so you can focus on growing your business.</h1>
                        </div>
                    </Col>
                </Row>
                <div className="bannericons">
                    <div>
                        <img width="5%" src={require("../../Assests/icons/itservice1.gif")} />
                    </div>

                    <div>
                        <img width="5%" src={require("../../Assests/icons/business.gif")} />
                    </div>
                    <div>
                        <img width="5%" src={require("../../Assests/icons/hrsolution.gif")} />
                    </div>
                </div>

            </Container>
        </div>
    )
}

export default Bannerservice;