import React, { useEffect } from "react";
import "../Clients/Clients.css"
import Aos, { init } from "aos";
import "aos/dist/aos.css";
import { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Simpleslider1 } from "../Simpleslider1/Slidee";
import CounterUpPage from "../CounterUpPage/CounterUpPage";
import { SimpleSlider } from "../SimpleSlider/Slidee";
import CLOUDS from "vanta/src/vanta.net";
import { Helmet } from "react-helmet";


function Clients() {
    useState(() => {
        Aos.init({ duration: 2000 });
    }, []);

    const currentPathname = window.location.pathname;
    const newPathname = "/clients";
    window.history.pushState({}, "", newPathname);

    useEffect(() => {
        CLOUDS({
            el: "#vanta",
            mouseControls: true,
            touchControls: true,
            gyroControls: false,
            minHeight: 200.00,
            minWidth: 200.00,
            scale: 1.00,
            scaleMobile: 1.00,
            color: 0x969696,
            backgroundColor: 0x24262b,
            blurFactor: 0.90,
        })
    }, []);

    useEffect(() => {
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth'
        });
      }, []);

    return (
        <div className="clients">
            <Helmet>
                <title>Clients - We make happy clients by interconnecting technology with business & art.</title>
                <meta name="description" content="We make happy clients by interconnecting technology with business & art." />
            </Helmet>

            <div className='bannersection'>

                <div style={{ height: "100vh" }} className='bg' id='vanta'>
                    <Container>
                        <Row>
                            <Col lg={12}>

                                <div className='bannertitle'>
                                    <p>Clients</p>
                                    <h1>We make happy clients by interconnecting technology with business & art.</h1>
                                </div>

                            </Col>

                        </Row>

                    </Container>
                </div>

            </div>

            <div className="background">
                <div className="animated-typing2">
                    <h1>Our Clients</h1>
                </div>
                <div className="head1">
                    <h1>Domestic Clients</h1>
                    <SimpleSlider />
                </div>
            </div>
            <div>
                <Container fluid className="clientscontainer">
                    <div >
                        <div className="head4">
                            <h1>Happy clients with <CounterUpPage /> successful projects</h1>
                        </div>
                    </div>
                </Container>
            </div>
            <div className="background">
                <div className="head2">
                    <h1>International clients</h1>
                    <Simpleslider1 />
                </div>
            </div>

            <div className="animated-typing2">
                <h1>Expertise</h1>
            </div>
            <div className="head6">
                <h1>Domains we have worked for.</h1>
            </div>
            <Container>
                <Row>

                    <Col lg={2} sm={6} md={6}>
                        <div className="img1">
                            <img width="100%" src={require("../../Assests/clientimg/it.gif")} />
                            <h2 className="industries">IT</h2>
                        </div>
                    </Col>

                    <Col lg={2} sm={6} md={6}>
                        <div className="img2">
                            <img width="100%" src={require("../../Assests/clientimg/Automobile.gif")} />
                            <h2 className="industries">Automobile</h2>
                        </div>
                    </Col>
                    <Col lg={2} sm={6} md={6}>
                        <div className="img3">
                            <img width="100%" src={require("../../Assests/clientimg/insurance.gif")} />
                            <h2 className="industries">Insurance</h2>
                        </div>
                    </Col>
                    <Col lg={2} sm={6} md={6}>
                        <div className="img4">
                            <img width="100%" src={require("../../Assests/clientimg/Heathcare.gif")} />
                            <h2 className="industries">Healthcare</h2>
                        </div>
                    </Col>
                    <Col lg={2} sm={6} md={6}>
                        <div className="img5">
                            <img width="100%" src={require("../../Assests/clientimg/engineering.gif")} />
                            <h2 className="industries">Engineering</h2>
                        </div>
                    </Col>
                    <Col lg={2} sm={6} md={6}>
                        <div className="img5">
                            <img width="100%" src={require("../../Assests/clientimg/logistics.gif")} />
                            <h2 className="industries">Logistics</h2>

                        </div>
                    </Col>
                </Row>
            </Container>

            <Container>
                <Row className="img20">
                    <Col lg={2} sm={6} md={6}>
                        <div className="img5">
                            <img width="100%" src={require("../../Assests/clientimg/finance.gif")} />
                            <h2 className="industries">Finance</h2>
                        </div>
                    </Col>
                    <Col lg={2} sm={6} md={6}>
                        <div className="img5">
                            <img width="100%" src={require("../../Assests/clientimg/entertainment.gif")} />
                            <h2 className="industries">Entertainment</h2>
                        </div>
                    </Col>
                    <Col lg={2} sm={6} md={6}>
                        <div className="img5">
                            <img width="100%" src={require("../../Assests/clientimg/retail.gif")} />
                            <h2 className="industries">Retail</h2>
                        </div>
                    </Col>
                    <Col lg={2} sm={6} md={6}>
                        <div className="img5">
                            <img width="100%" src={require("../../Assests/clientimg/construction.gif")} />
                            <h2 className="industries">Construction</h2>
                        </div>
                    </Col>

                </Row>
            </Container>

        </div>
    )
};
export default Clients;