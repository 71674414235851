import React, { useEffect } from "react";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import "./Scrolltriger.css";

// Initialize ScrollTrigger

gsap.registerPlugin(ScrollTrigger);

function AnimatedGallery() {
  useEffect(() => {

    const details = gsap.utils.toArray(".desktopContentSection:not(:first-child)");
    const photos = gsap.utils.toArray(".desktopPhoto:not(:first-child)");

    gsap.set(photos, { yPercent: 101 });

    const allPhotos = gsap.utils.toArray(".desktopPhoto");

    const isDesktop = () => window.innerWidth >= 300;

    const animateDesktop = () => {
      console.log("desktop");

      ScrollTrigger.create({
        trigger: ".gallery1",
        start: "top top",
        end: "bottom bottom",
        pin: ".right",
      });

      details.forEach((detail, index) => {
        let headline = detail.querySelector("h1");
        let animation = gsap.timeline().to(photos[index], { yPercent: 0 }).set(allPhotos[index], { autoAlpha: 0 });
        ScrollTrigger.create({
          trigger: headline,
          start: "top 30%",
          end: "top 0%",
          animation: animation,
          scrub: true,
          markers: false,
        });
      });
    };

    let initialIsDesktop = isDesktop();

    if (initialIsDesktop) {
      animateDesktop();
    }

    const handleResize = () => {
      const currentIsDesktop = isDesktop();
      if (currentIsDesktop && !initialIsDesktop) {
        animateDesktop();
      }
      initialIsDesktop = currentIsDesktop;
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
     
      <div className="gallery1">
        <div className="left">
          <div className="desktopContent">
            <div className="desktopContentSection">
              <h1>Vision</h1>
              <p>To become the leading provider of sustainable and innovative technology solutions, transforming businesses worldwide.
                In the context of a business, vision refers to a clear and inspiring description.</p>
            </div>
            <div className="desktopContentSection">
              <h1>Mission</h1>
              <p>A mission statement is a concise and declarative statement that defines the fundamental purpose of an organization, explaining why it exists and what it aims to achieve.
                It serves as a guiding principle </p>
            </div>
            <div className="desktopContentSection">
              <h1>Values</h1>
              <p>Values are fundamental beliefs and principles that guide the behavior, decisions, and actions of individuals, organizations, or societies.</p>
            </div>
          </div>
        </div>

        <div className="right">
          <div className="mobileContent">
            <div className="mobilePhoto red"></div>
            <h1>Red</h1>
            <p>Red is a color often associated with strong emotions such as passion, love, and anger. It's a bold and attention-grabbing color that can evoke feelings of excitement, warmth, and energy.</p>

            <div className="mobilePhoto green"></div>
            <h1>Green</h1>
            <p>Green is a color that is often associated with nature, growth, and harmony. It is a calming and relaxing color that can evoke feelings of balance, stability, and freshness. In color psychology, green is said to represent balance and stability, making it a popular choice for branding and marketing in the health and wellness industry. </p>

            <div className="mobilePhoto pink"></div>
            <h1>Pink</h1>
            <p>Pink is a color that is often associated with femininity, romance, and sweetness. It is a softer and more delicate shade of red that can evoke feelings of warmth, love, and nurturing. In the world of branding and marketing, pink is often used to target a female audience or to promote products that are associated with beauty, love, or romance.</p>

            <div className="mobilePhoto blue"></div>
            <h1>Blue</h1>
            <p>Blue is a color that is often associated with calmness, trust, and reliability. It is a peaceful and serene color that can evoke feelings of stability, security, and professionalism. In color psychology, blue is said to represent loyalty and trust, making it a popular choice for branding and marketing in the finance and technology industries.</p>
          </div>

          <div className="desktopPhotos">
            <div className="desktopPhoto red"></div>
            <div className="desktopPhoto green"></div>
            <div className="desktopPhoto pink"></div>
            <div className="desktopPhoto blue"></div>
          </div>
        </div>
      </div>

    </>
  );
}

export default AnimatedGallery;